<template>
  <div
    class="banner"
    :style="{
      background: dataSource.length
        ? dataSource[bannerIndex]
          ? dataSource[bannerIndex].bgColor
          : ''
        : '',
    }"
  >
    <div class="content">
      <div
        class="card"
        :class="{ long: module.bannerType != 1, short: module.bannerType == 1 }"
      >
        <div class="folder" v-if="module.bannerType == 1">
          <div
            class="parent-item"
            v-for="(p, pI) in folderData"
            :key="p.id"
            @click="handleFolder([pI], p)"
          >
            <span class="parent-name">{{
              p.id == "more" ? $t(p.name) : p.name
            }}</span>
            <RightOutlined v-if="(p.list && p.list.length) || p.id == 'more'" />
            <div class="children-box" v-if="p.list && p.list.length">
              <div
                class="children-item"
                v-for="(c, cI) in p.list"
                :key="c.id"
                @click.stop="handleFolder([pI, cI], c)"
              >
                <span class="children-name">{{ c.name }}</span>
                <div class="other-box" v-if="c.list && c.list.length">
                  <span
                    class="other-item"
                    v-for="(t, tI) in c.list"
                    :key="t.id"
                    @click.stop="handleFolder([pI, cI, tI], t)"
                  >
                    {{ t.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel">
          <a-carousel
            autoplay
            arrows
            :dots="false"
            :before-change="beforeChange"
            effect="fade"
          >
            <template #prevArrow>
              <div class="carousel-arrow-l">
                <LeftOutlined class="icon" />
              </div>
            </template>
            <template #nextArrow>
              <div class="carousel-arrow-r">
                <RightOutlined class="icon" />
              </div>
            </template>
            <div
              class="carousel-item"
              v-for="(item, index) in dataSource"
              :key="index"
            >
              <div
                class="img"
                @click="jump(item)"
                :style="[
                  (item.jumpType == 1 && item.jumpUrl == '') ||
                  (item.jumpType == 2 && item.resourceId == 0)
                    ? ''
                    : 'cursor: pointer;',
                ]"
              >
                <img :src="item.coverPath" alt="banner" />
              </div>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import ls from "@/utils/local-storage";
import { courseFolder } from "@/api/course";
export default {
  name: "banner",
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const router = useRouter();

    const bannerIndex = ref(0);
    const beforeChange = (from, to) => {
      bannerIndex.value = to;
    };
    const jump = (data) => {
      emit("jump", data);
    };

    const folderData = ref([]);
    courseFolder().then((res) => {
      if (res.ret === 0 && res.data) {
        if (res.data.length > 8) {
          folderData.value = res.data.slice(0, 7);
          folderData.value.push({
            name: "XB_More",
            id: "more",
          });
        } else {
          folderData.value = res.data;
        }
      }
    });
    const handleFolder = (depths, item) => {
      if (item.id != "more") {
        ls.set("courseFolder", {
          depths,
          folderId: item.id,
        });
      }
      router.push({
        path: "/course",
      });
    };

    return {
      bannerIndex,
      beforeChange,
      jump,
      folderData,
      handleFolder,
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  transition: background 500ms ease 0s;
  .content {
    .mixinWrap(1105px);
    .card {
      .carousel-arrow-l,
      .carousel-arrow-r {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        text-align: center;
        padding: 9px 0;
        background-color: #000;
        z-index: 1;
        opacity: 0.3;
        transition: opacity 0.3s ease;
        .icon {
          color: #fff;
          font-size: 30px;
          height: 30px;
          line-height: 30px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      .carousel-arrow-l {
        left: 12px;
        top: calc(50% - 18px);
      }
      .carousel-arrow-r {
        right: 12px;
        top: calc(50% - 18px);
      }
      &.long {
        height: 389px;
        .carousel {
          width: 100%;
          height: 389px;
          &-item {
            .img {
              width: 100%;
              height: 389px;
              overflow: hidden;
              .mixinFlex(center; center);
              img {
                width: 100%;
                height: 495px;
              }
            }
          }
        }
      }
      &.short {
        height: 389px;
        .mixinFlex();
        .folder {
          width: 236px;
          background-color: rgba(0, 0, 0, 0.3);
          font-size: 20px;
          z-index: 99;
          padding: 2px 0 2px 8px;
          position: relative;
          .parent-item {
            .mixinFlex(space-between; center);
            width: 228px;
            height: 40px;
            margin: 7px 0;
            padding: 10px 20px;
            border-radius: 4px 0 0 4px;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            transition: background-color 0.3s ease;
            .parent-name {
              width: calc(100% - 26px);
              .mixinEllipsis(20px);
            }
            &:hover {
              background-color: #fff;
              color: var(--theme);
              .children-box {
                opacity: 1;
                transform: scaleX(1);
                visibility: inherit;
              }
            }
          }
          .children-box {
            position: absolute;
            left: 235px;
            top: 0;
            bottom: 0;
            opacity: 0;
            transform: scaleX(0.8);
            transform-origin: 0 0;
            width: 590px;
            background-color: #fff;
            visibility: hidden;
            transition: all 0.2s ease;
            overflow-y: auto;
            padding: 20px 4px 4px 20px;
            box-shadow: 0 0 20px 0 rgba(148, 148, 148, 0.1);
            .children-item {
              .mixinFlex(flex-start; flex-start);
              flex-wrap: wrap;
              width: 100%;
              font-size: 16px;
              .children-name {
                font-size: 14px;
                font-weight: 600;
                color: #333;
                line-height: 24px;
                margin-right: 16px;
                margin-bottom: 16px;
                &:hover {
                  color: var(--theme);
                }
              }
              .other-box {
                flex: 1;
                span {
                  display: inline-block;
                  margin-left: 16px;
                  margin-bottom: 16px;
                  font-size: 14px;
                  color: #666;
                  line-height: 24px;
                  vertical-align: top;
                  &:hover {
                    color: var(--theme);
                  }
                }
              }
            }
          }
        }
        .carousel {
          width: 869px;
          height: 389px;
          &-item {
            .img {
              .mixinImgWrap(100%; 389px);
            }
          }
        }
      }
    }
  }
}
</style>
